.home{
    overflow-x: hidden;
    height: fit-content;
    overflow-y: hidden;
}

.carousel img{
    width: 100%;
    object-fit: cover;
}
