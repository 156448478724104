.room-container {
  width: 100%;
  padding-bottom: 40px;
}

.room__container {
  display: flex;
  flex-direction: row;
}

.room__images {
  width: 50%;
  height: 70vh;
  padding: 10px 60px;
}

.roomSwipper {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.roomSwipper .swiper-slide-active img {
  width: 100%;
  height: 100%;
}

.content {
  font-family: 'Poppins';
  padding: 0 50px;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: cursive;
}

.content h4{
  font-family: 'Dancing Script';
  font-size: 2.25rem;
}

.content h3 {
  color: rgb(148, 82, 82);
}

@media (max-width: 992px) {
  .room__container {
    flex-direction: column;
  }

  .room__images {
    width: 100%;
    height: 50vh;
  }

  .content{
    padding-top: 40px;
  }
}

@media (max-width: 576px){
    .room__images{
        height: 35vh;
        padding: 10px 30px;
    }

    .content{
        font-size: 1rem;
    }
}
