.contact__subheading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.contact__subheading p {
  font-family: 'Dancing Script';
  padding-right: 10px;
}

.contact__subheading span {
  font-family: 'Dancing Script';
  color: rgb(148, 82, 82);
}

iframe{
  padding: 20px 100px;
  width: 100%;
  height: 50vh;
}


.contact__details {
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 80%;
  row-gap: 20px;
  column-gap: 20px;
}

.contact__card {
  background-color: whitesmoke;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  gap: 1rem;
}

.contact__card h4 {
  font-weight: normal;
  font-family: 'Poppins';
}

@media (max-width: 992px) {
  .chakra-container.contact__container {
    padding-top: 25px;
    height: 93vh;
  }

  .contact__card {
    height: 120px;
  }

}

@media (max-width: 780px) {
  .chakra-container.contact__container {
    height: 97vh;
  }

}

@media (max-width: 576px) {
  .chakra-container.contact__container {
    height: fit-content;
  }

  iframe{
    padding: 20px 10px;
  }

  .contact__subheading{
    display: flex;
    flex-direction: column;
  }

  .contact__details {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact__card h3,
  .contact__card h4 {
    font-size: small;
  }
}

@media (max-width: 327px) {
  .contact__container h2 {
    margin: 0 35px;
  }

  .chakra-container.contact__container {
    height: fit-content;
  }

  .contact__subheading{
    padding-top: 20px;
    font-size: 1.8rem;
  }
}
