/* Scroll Up */
.scrollup {
  z-index: 1;
  position: fixed;
  right: 1rem;
  bottom: -50%;
  background-color: rgb(148, 82, 82);
  box-shadow: 0 4px 12px hsla(19, 64%, 24%, 0.1);
  display: inline-flex;
  padding: 0.35rem;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  color: white;
  transition: bottom 0.3s, transform 0.3s, background 0.4s;
}

.scrollup:hover {
  transform: translateY(-0.25rem);
}

/* Show Scroll Up */
.show-scroll {
  bottom: 3rem;
}
