.about__container {
  max-height: 400vh;
  width: 100%;
  background-color: whitesmoke;
  padding-bottom: 30px;
}

.about__subheading {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding-bottom: 50px;
}

#typewriter {
  font-size: 2.8rem;
  font-family: 'Poppins';
}

.about__subheading p {
  padding-right: 10px;
  font-family: 'Dancing Script', cursive;
}

.about__subheading span {
  font-family: 'Dancing Script', cursive;
  color: rgb(148, 82, 82);
}

.horizontal__grid {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

.typewriter {
  font-size: 2rem;
  padding-bottom: 20px;
}

.horizontal__grid img {
  height: 75vh;
  border-radius: 20px;
  object-fit: cover;
  width: inherit;
  margin: 0 auto;
}

.about__content {
  padding: 40px 0px;
}

.about__content li {
  padding: 10px 30px;
  display: list-item;
  list-style-type: square;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: cursive;
}

@media (max-width: 992px) {
  .about__container {
    height: fit-content;
  }

  .horizontal__grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .horizontal__grid img {
    height: 500px;
    width: 650px;
  }

  .typewriter {
    padding: 35px 54px;
  }

  .about__content {
    padding: 20px 55px;
  }

  iframe {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {

  .about__subheading{
    display: flex;
    flex-direction: column;
  }

  .horizontal__grid {
    width: 100%;
  }

  .horizontal__grid img {
    width: fit-content;
    height: 400px;
    border-radius: 70px;
  }

  .typewriter {
    font-size: 1.2rem;
    padding: 20px 43px;
  }

  .about__content {
    padding: 10px 47px;
  }

  .about__content li {
    font-size: 0.8rem;
    display: list-item;
    list-style-type: square;
  }

  #typewriter{
    font-size: 2.4rem;
    align-items: center;
  }

  iframe {
    padding: 40px;
  }
}

@media (max-width: 327px) {
  .about__subheading {
    font-size: 1.8rem;
  }

  .horizontal__grid img {
    width: 220px;
    height: 300px;
  }

  .about__content {
    padding-bottom: 30px;
  }

  .about__content li {
    padding: 0px 10px;
    font-size: 0.7rem;
  }

  h3.typewriter {
    font-size: 1.2rem;
  }

  iframe {
    padding: 40px;
  }
}
