.menudetails__container{
    width: 99vw;
    height: 900px;
    background-color: #fffef2;
    padding: 20px 0;
    overflow-x: hidden;
}

.pdf__container{
    display: flex;
    width: 60vw;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.3);
    height: 850px;
}

.pdf__container ::-webkit-scrollbar{
    display: none;
}

@media (max-width: 840px){
    .pdf__container{
        width: 90vw;
    }
}

@media (max-width: 450px) {
    .pdf__container{
        width: 100vw;
    }
}