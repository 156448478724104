.gallery__subheading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.gallery__subheading h4{
    font-family: 'Dancing Script';
    padding-right: 10px;
}

.gallery__subheading p{
    font-family: 'Dancing Script';
    color: rgb(148, 82, 82);
}

.mySwiper{
    width: 95%;
    height: fit-content;
    margin: 25px;
    z-index: 3;
}

.mySwiper img{
    height: 400px;
    width: 400px;
    object-fit: cover;
}

@media (max-width: 992px){
    .gallery__container{
        height: fit-content;
    }

    .mySwiper{
        height: 45%;
    }

    .mySwiper img{
        height: 300px;
        width: 300px;
    }
}

@media (max-width: 576px) {
    .mySwiper img{
        padding: 10px;
        width: 100%;
    }
}

@media (max-width: 327px){
    .gallery__subheading{
        padding-top: 20px;
        font-size: 1.8rem;
    }
}