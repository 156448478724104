.booktablecontainer {
  width: 100vw;
  height: 80vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.booktableimage > img {
  height: 50vh;
}

.detailField {
  background-color: rgb(255, 249, 249);
  padding: 70px 40px;
  width: 40vw;
  height: 50vh;
}

.details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}

.details > input {
  height: 80px;
  min-width: 100px;
  width: 100%;
  outline: none;
  text-align: center;
  font-size: 1rem;
  border: 1px solid black;
  border-radius: 5px;
}

input[type='date'] {
  background-color: white;
  min-width: 100px;
  color: black;
  font-size: 18px;
  border: 1px solid black;
  padding: 10px;
  outline: none;
  border-radius: 5px;
}
::-webkit-calendar-picker-indicator {
  background-color: rgb(255, 249, 249);
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.message {
  margin-top: 30px;
  text-align: center;
  width: 100%;
  height: 100px;
  border: 1px soild black;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 8px;
}

.submitButton {
  background-color: rgb(148, 82, 82);
  border: 0;
  padding: 14px 60px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  font-size: 1.1rem;
  margin: 20px 180px;
  width: 40%;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .booktableimage > img {
    height: 60vh;
  }

  .detailField {
    padding: 40px 30px;
    width: 50vw;
    height: 60vh;
  }
}

@media (max-width: 992px) {
  .booktablecontainer {
    overflow-x: hidden;
    flex-direction: column;
    height: fit-content;
    padding-top: 20px;
    width: 100%;
  }

  .booktableimage > img {
    margin-top: 20px;
    width: 700px;
    border-radius: 20px;
  }

  .detailField {
    width: 85%;
    height: fit-content;
    margin-bottom: 40px;
  }

  .details {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }

  .details > input {
    width: 100%;
    font-size: 1.2rem;
  }

  .submitButton {
    width: 100%;
    margin: 30px 0;
  }

  .message {
    height: 200px;
    font-size: 1.2rem;
  }
}

@media (max-width: 780px) {
  .booktableimage > img {
    width: 650px;
  }
}

@media (max-width: 576px) {
  .booktableimage > img {
    width: 350px;
  }
}

@media (max-width: 327px) {
  .booktableimage > img {
    width: 250px;
  }

  .details > input {
    font-size: 0.8rem;
  }

  .detailField textarea {
    font-size: 0.8rem;
  }
}
