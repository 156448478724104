.chakra-container.menu{
  background-color: #fffef2;
}

.hide {
  display: none;
}

.show {
  display: block;
}

#italics{
  font-family: cursive;
  font-weight: bold;
  font-style: italic;
}

.menu {
  padding: 40px;
}

.active {
  color: rgb(148, 82, 82) !important;
  transform: scale(1.07);
}

.menu__subheading {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.menu__subheading p {
  padding-right: 10px;
  font-family: 'Dancing Script', cursive;
}

.menu__subheading span {
  font-family: 'Dancing Script', cursive;
  color: rgb(148, 82, 82);
}

.menu__nav {
  margin: 0 10px;
  padding: 10px 5px;
  transition: 0.3s;
  height: 100px;
  align-items: center;
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.menu__nav li a h4 {
  font-family: 'Poppins';
  margin-top: 40px;
  font-size: 1.3rem;
  font-weight: 500;
  color: black;
}

.menu__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 20px;
  width: 80%;
  margin: 40px auto;
}

.menu__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu__card img {
  height: 300px;
  width: 300px;
  border-radius: 50%;
}

.menu__card h4 {
  padding-top: 20px;
  font-family: 'Poppins';
  font-size: 1.5rem;
  color: rgb(148, 82, 82);
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
}

.more a {
  background-color: rgb(148, 82, 82);
  color: white;
  padding: 10px;
  font-size: large;
  font-weight: 500;
  border-radius: 10px;
}

@media (max-width: 992px) {
  .chakra-container.menu {
    height: fit-content;
  }

  .menu__nav {
    width: 70%;
  }

  .menu__card img {
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 780px) {
  .menu__card img {
    height: 180px;
    width: 180px;
  }

  .menu__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .chakra-container.menu {
    padding: 0;
    padding-bottom: 30px;
  }

  .menu__cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .menu__subheading {
    display: flex;
    flex-direction: column;
  }

  .menu__nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    line-height: 0.2rem;
    padding-bottom: 50px;
  }

  .menu__nav li a h4 {
    align-items: center;
    font-size: 1rem;
  }

  .menu__card img {
    height: 300px;
    width: 300px;
  }

  .more {
    justify-content: center;
  }
}

@media (max-width: 327px) {
  .menu__subheading{
    display: flex;
    flex-direction: column;
  }

  .menu__subheading p{
    padding-top: 5px;
    padding-bottom: 8px;
  }

  .menu__cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .menu__nav{
    display: flex;
    flex-direction: column;
    height: fit-content;
    line-height: 0.2rem;
    padding-bottom: 50px;
  }

  .show{
    width: 100%;
  }

  .menu__nav li a h4 {
    font-size: 0.8rem;
  }

  .menu__card img {
    height: 180px;
    width: 180px;
  }

  .menu__card h4{
    font-size: 1.2rem;
  }

  .more {
    justify-content: center;
  }

  .more a{
    font-size: 1rem;
  }
}
