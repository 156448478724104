.chakra-container.container{
    height: fit-content;
}

.moments__subheading{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.moments__subheading h4{
    font-family: 'Dancing Script';
    padding-right: 10px;
}

.moments__subheading p{
    font-family: 'Dancing Script';
    color: rgb(148, 82, 82);
}

.moments__carousel{
    padding-top: 20px;
}

@media (max-width: 560px){
    .moments__subheading{
        flex-direction: column;
        font-size: 2.6rem;
    }

    .moments__subheading h4{
        padding-right: 0;
    }
}


@media (max-width: 327px){
    .moments__box{
        height: 200px;
    }

    .chakra-container.container{
        height: fit-content;
    }

    .moments__subheading{
        padding-top: 20px;
        font-size: 1.8rem;
    }
}