.footer__container{
    padding: 25px;
}

.grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    padding: 0 25px
}

#address{
    padding: 0 83px;
}

.grid__card{
    height: 140px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.grid__card h2{
    font-size: 18px;
}

.grid__card p{
    font-size: 14px;
    font-family: 'Poppins';
    text-align: center;
}

.icons a svg{
    font-size: 2.5rem;
    color: white;
}

.icons a svg:hover{
    transform: translateY(-5px);
    transition: transform 0.5s;
}

@media (max-width: 992px){
    .chakra-container.footer__container{
        height: fit-content;
    }

    #address{
        padding: 0px !important;
    }
}


@media (max-width: 576px){
    .grid{
        grid-template-columns: repeat(1, 1fr);
    }

    .copyright{
        font-size: 1rem;
    }

    .design{
        font-size: 1rem;
    }
}

