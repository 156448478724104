@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior:smooth;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  font-size: 1rem;
}

header {
  position: relative;
  padding: 0 2rem;
  background-color: whitesmoke;
}

.navbar {
  width: 100%;
  height: 60px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo a {
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  color: black;
}

.navbar .logo img {
  height: 40px;
  padding-right: 20px;
}

.navbar .links {
  display: flex;
  gap: 2rem;
}

.navbar .links a {
  font-family: 'Poppins', sans-serif;
  color: black;
}

.navbar .toggle_btn {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

.action_btn {
  background-color: rgb(148, 82, 82);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins';
  transition: scale 0.2s ease-in;
}

.action_btn:hover {
  scale: 1.05;
  color: #fff;
}

.action_btn:active {
  scale: 0.95;
}

/* Dropdown Menu */
.dropdown_menu {
  display: none;
  position: absolute;
  right: 2rem;
  top: 60px;
  height: 0;
  width: 300px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open {
  z-index: 999;
  height: 350px;
}

.dropdown_menu li {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_menu .action_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
}

/* Responsive Menu */
@media (max-width: 992px) {
  .navbar {
    height: 6rem;
  }

  .navbar .links,
  .navbar .action_btn {
    display: none;
  }

  .navbar .toggle_btn {
    display: block;
    color: black;
  }

  .dropdown_menu {
    z-index: 1;
    background-color: lightgray;
    margin-top: 2rem;
    display: block;
  }

  .dropdown_menu li a {
    color: black;
  }
}

@media (max-width: 576px) {
  .dropdown_menu {
    left: 2rem;
    width: unset;
  }
}

@media (max-width: 327px) {
  header {
    padding: 0px;
  }

  .navbar {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}
